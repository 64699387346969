.drawer-show {
  width: 100%;
  transition: width 0.8s ease-out;
}

.drawer-hide {
  width: 0;
  transition: width 0.8s ease-out;
}

.hide-drawer-content {
  opacity: 0;
  display: none;
  transition: all 0.2s ease-out;
}

.drawer-delay {
  transition: all 0.4s ease-out;
}

.navbar-brand.text-dark.active {
  font-weight: bold;
}

.mt-100 {
  margin-top: 100px;
}