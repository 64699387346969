.feature-box {
  &:hover {
    background-color: $custom-primary !important;
    color: #fff;
  }
}

.car-model-box {
  &:hover {
    background-color: $custom-primary-100 !important;
  }
  button {
    background-color: $custom-primary !important;
    color: #fff;
  }
}


.greate-feature {
  position: absolute;
  right: 0;
  width: 300px;
  &--main {
    top: 10px;
    @include media-breakpoint-down(md) {
      top: 80px;
    }
  }
  &--sub {
    top: 30px;
    @include media-breakpoint-down(md) {
      top: 120px;
    }
  }
}

.greate-feature-margin {
  @include media-breakpoint-down(md) {
    margin-bottom: 100px;
  }
}

.feature-icons {
  top: -15px;
  right: 30px;
  width: 75px;

  @include media-breakpoint-down(sm) {
    width: 60px;
  }
}

.cover-img {
  object-fit: contain;
  object-position: 0 50%;
  width: 100%;
  height: 100%;

  @include media-breakpoint-down(md) {
    object-fit: cover;
  }
}

.text-sm {
  font-size: $font-size-sm;
}

.text-xs {
  font-size: $font-size-xs;
}
.text-lg {
  font-size: $font-size-lg;
}
.dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border .24s ease-in-out;
}

.button-group {
  color: #bdbdbd;
}

