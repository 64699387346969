.hoverable-bg {
  &:hover {
    background-color: $primary;

    & button {
      color: #fff !important;
    }
  }
}

// .btn.active.btn-light {

// }

.input-radio {
  border:  1px solid $gray-300;
  &.active {
    background-color: $primary;
    border:  1px solid $gray-300;
  }
}

.input-state {
  border:  1px solid $gray-300;
  &.active {
    background-color: $primary;
    border:  1px solid $gray-300;
  }
}

.input-checkbox {
  background-color: #fff;
}

label input[type="radio"] {
  visibility: hidden;
}
.ReactModal__Html--open,
.ReactModal__Body--open {
  overflow: hidden; /* prevents background page from scrolling when the modal is open */
}

.ReactModal__Overlay {
  z-index: 1060;
  width: 100vw;
  max-height: 100vh;
  overflow-y: auto;
}

.MuiSvgIcon-root.MuiStepIcon-root {
  &:hover {
    cursor: pointer; 
  }
}

.MuiTab-textColorPrimary.Mui-selected {
  color: $custom-primary-900
}

.flex-glow {
  flex-grow: 1;
}
.btn-group {
  label.btn-sm {
    padding-left: 0;
  }
}