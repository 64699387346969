.bg-dark-transparent {
  background-color: rgba(#000, 0);
}

.bg-dark-15-transparent {
  background-color: rgba(#000, 0.5);
  &:hover {
    background-color: rgba(#000, 0.85);
  }
}

.bg-danger-15-transparent {
  background-color: rgba($danger, 0.5);
  &:hover {
    background-color: rgba($danger, 0.85);
  }
}
